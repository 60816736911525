import {DateRangeResponse} from '../api/models/date-range-response';
import {formatDate, formatNumber, formatPercent} from '@angular/common';
import {OAuthProviderType} from '../api/models/o-auth-provider-type';
import {DICTIONARY} from '../modules/global/pipes/enum-formatter.pipe';
import {CurrentUserResponse} from '../api/models/current-user-response';
import {Role} from '../api/models/role';
import {CompanyResponse} from '../api/models/company-response';
import {AvailableProvidersSummaryResponse} from '../api/models/available-providers-summary-response';
import {
  AccountingIntegrationType,
  AvailableProviderResponseExtended
} from '../modules/portal/components/profile/business/data-collection-hub/models/available-provider-response-extended';

export function getTypeOfContent(contentType: string): string {
  if (contentType.startsWith('image')) return 'Image';
  if (contentType.startsWith('video')) return 'Video';
  if (contentType.endsWith('pdf')) return 'PDF file';
  if (contentType.endsWith('docx')) return 'Document file';
  return 'Other';
}

export function getTypeOfUrl(url: string): string {
  const youtube = 'Youtube';
  const urlLower = url.toLowerCase();
  if (urlLower.includes('youtu.be')) return youtube;
  if (urlLower.includes('youtube.com')) return youtube;

  return 'Other';
}

export function filterCognitoAwsMessage(message: string): string {
  if (!message) return '';

  return message
    .replace('PreSignUp failed with error ', '')
    .replace('PreAuthentication failed with error ', '');
}

export function formatDateRangeResponse(dateRange: DateRangeResponse, locale: string = 'en-AU', format: string = 'dd MMM yyyy') {
  return dateRange ? `${formatDate(dateRange.initDate, format, locale)} - ${formatDate(dateRange.endDate, format, locale)}`
    : null;
}

export function formatPercentageResponse(value: number, locale: string = 'en-AU', tolerance: number = 5) {
  const rest = (value * 100) - 100;
  if (rest > 0 && rest <= tolerance) {
    value = 1;
  }
  if (rest < 0 && Math.abs(rest) <= tolerance) {
    value = 1;
  }
  return formatPercent(value, locale);
}

export function formatEnumResponse(value): string {
  const titleCase = (word) => {
    return word.replace(/([A-Z\d]+)/g, ' $1', (text) => {
      return text.toUpperCase();
    });
  };

  if (!value) {
    return '';
  }
  if (DICTIONARY[value]) {
    return DICTIONARY[value];
  }

  return titleCase(value).trim();
}

// OAuthProvider common functions
export function getOAuthProvider(providerName: string): OAuthProviderType {
  const providerNameLowerCase = (providerName ?? '').toLowerCase();
  if (providerNameLowerCase === 'xero') {
    return OAuthProviderType.Xero;
  }

  if (providerNameLowerCase === 'quickbooks') {
    return OAuthProviderType.QuickBooks;
  }

  return null;
}

export function getOAuthProviderLogo(providerType: OAuthProviderType): string {
  switch (providerType) {
    case OAuthProviderType.Xero:
      return '/assets/portal/svgs/integration-logos/xero.svg';
      //return '/assets/portal/images/integrations/xero-new.png';
    case OAuthProviderType.QuickBooks:
      return '/assets/portal/svgs/integration-logos/quickbooks.svg';
      //return '/assets/portal/images/integrations/quick-books.png';
    default:
      return '';
  }
}

/**
 * Used to validate that the current user has a partner. This is useful when the user navigate to another company
 * @param user
 */
export function validPartner(user: CurrentUserResponse): boolean {
  return user.partnerInfo?.enabled && user.partnerInfo?.rolesInPartner.findIndex(r => r === Role.PartnerAdmin) >= 0;
}

/**
 * This indicates that the current company is Partner or not (Some options like managing users require this logic)
 * @param company
 */
export function validCompanyPartner(company: CompanyResponse): boolean {
  return company.partnerInfo?.enabled;
}

export function toTitleCase(label: string): string {
  return label
    .replace(/([A-Z])/g, ' $1')
    .replace(/^./, (text: string) => text.toUpperCase());
}

export function toFormattedCO2(co2Tons: number): number {
  return co2Tons > 0 && co2Tons < 0.1
    ? co2Tons * 1000
    : co2Tons;
}

/**
 * Convert CO2 tons to a string that includes the Unit.
 * The Unit depends on the CO2 amount, if we have less than 0.1 tons, we convert them to kgs.
 * @param co2Tons
 * @param locale
 */
export function convertCo2ToReadableUnit(co2Tons: number, locale: string = 'en-AU'): string {
  if (co2Tons > 0 && co2Tons < 0.1) {
    const co2Kgs = co2Tons * 1000;
    return `${formatNumber(co2Kgs, locale, '1.0-1')}kg`;
  }
  return `${formatNumber(co2Tons, locale, '1.0-1')}t`;
}

/**
 * Return the value as a percentage string with a variable number of decimals
 * The number of decimals depends on how small is the value, if we have a value less than 0.001 we return 4 decimals, 1 in other case
 * @param value
 * @param locale
 */
export function adaptivePercentFormatter(value: number, locale: string = 'en-AU'): string {
  if (value > 0 && value < 0.001) {
    return formatPercent(value, locale, '1.0-4');
  }
  if (value >= 1) {
    return formatPercent(value, locale, '1.0-0');
  }

  return formatPercent(value, locale, '1.0-1');
}

/**
 * Return the value as a string with a variable number of decimals
 * The number of decimals depends on how small is the value, if we have a value less than 0.1 we return 4 decimals, 1 in other case
 * @param value
 * @param locale
 */
export function adaptiveDecimalFormatter(value: number, locale: string = 'en-AU'): string {
  if (value > 0 && value < 0.1) {
    return formatNumber(value, locale, '1.0-4');
  }
  return formatNumber(value, locale, '1.0-1');
}

/**
 * Retrieves extended information for available providers.
 *
 * @param {AvailableProvidersSummaryResponse} providers - The summary of available providers.
 * @returns {Object} - An object containing the available providers and providers not supported.
 * @returns {AvailableProviderResponseExtended[]} providers - The list of available providers with extended information.
 * @returns {AvailableProviderResponseExtended[]} providersAndNotSupported - The list of available providers and providers not supported with extended information.
 */
export function getAvailableProviderExtended(providers: AvailableProvidersSummaryResponse): {
  providers: AvailableProviderResponseExtended[]
  providersAndNotSupported: AvailableProviderResponseExtended[]
} {
  const availableProviders = providers.availableProviders.map(p => ({
    ...p,
    type: AccountingIntegrationType.OAuth,
    displayName: p.provider.displayName,
    isSelected: false
  } as AvailableProviderResponseExtended));

  const availableProvidersAndNotSupported = [
    ...availableProviders,
    {
      type: AccountingIntegrationType.Other,
      provider: null,
      displayName: 'We use another',
      connectedTenants: []
    } as AvailableProviderResponseExtended
  ];

  return {
    providers: availableProviders,
    providersAndNotSupported: availableProvidersAndNotSupported
  };
}

export function arraysEqual<T>(a: T[], b: T[], compareFn: (a: T, b: T) => boolean = (a1, b1) => a1 === b1): boolean {
  // Check if both arrays have the same length
  if (a.length !== b.length) {
    return false;
  }

  // Compare each element using the provided comparison function
  for (let i = 0; i < a.length; i++) {
    if (!compareFn(a[i], b[i])) {
      return false;
    }
  }

  return true;
}
